<script>
import Vue from 'vue'
import { SweetModal } from 'sweet-modal-vue'
import AppFooter from '@/components/AppFooter'
import { latLngBounds, latLng, icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet'

const icon1 = icon({
  iconUrl: "/images/icons/pin3.png",
  iconSize: [29, 53],
  iconAnchor: [15, 53]
})

const icon2 = icon({
  iconUrl: "/images/icons/pin2.png",
  iconSize: [29, 53],
  iconAnchor: [16, 37]
})

const icon3 = icon({
  iconUrl: "/images/icons/pin1.png",
  iconSize: [29, 53],
  iconAnchor: [16, 37]
})

export default {
  name: 'kontakt',
  components: {
    SweetModal,
    AppFooter,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  data() {
    return {
      zoom: 13,
      center: [0, 0],
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      prevedzkaText: Vue.prototype.$isSummer ? 'Požičovňa, servis a predaj elektrobicyklov a bicyklov' : 'Skiservis, požičovňa a predajňa doplnkov',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [
        {
          latLng: latLng(49.066115, 19.588151),
          icon: icon3,
          tooltipTitle: this.prevedzkaText,
          tooltipSubtitle: 'Liptovský Mikuláš - Demänová, Tatra Line Resort Demänová',
          click: () => this.$refs.modal10.open()
        },
        {
          latLng: latLng(49.0885388, 19.6031334),
          icon: icon1,
          tooltipTitle: 'Požičovňa, skiservis a predaj zimnej výstroje',
          tooltipSubtitle: 'Liptovský Mikuláš, Jánošíkovo nábrežie 6',
          click: () => this.$refs.modal1.open()
        },
        {
          latLng: latLng(48.967481, 19.573924),
          icon: icon1,
          tooltipTitle: 'Skiservis a požičovňa',
          tooltipSubtitle: 'Demänovská dolina, Jasná – Hotel SOREA MARMOT (J. ŠVERMA)',
          click: () => this.$refs.modal2.open()
        },
        {
          latLng: latLng(49.2124567, 19.275118),
          icon: icon1,
          tooltipTitle: 'Skiservis a požičovňa',
          tooltipSubtitle: 'Dolný Kubín, Penzión Kúria**',
          click: () => this.$refs.modal3.open()
        },
        {
          latLng: latLng(48.7072099, 19.1394844),
          icon: icon1,
          tooltipTitle: 'Skiservis a požičovňa',
          tooltipSubtitle: 'Banská Bystrica, Zvolenská cesta 14/4262 (za budovou ZVT, vedľa Okresného súdu)',
          click: () => this.$refs.modal4.open()
        },
        {
          latLng: latLng(49.0115507, 21.2348335),
          icon: icon1,
          tooltipTitle: 'Skiservis a požičovňa',
          tooltipSubtitle: 'Prešov, Sabinovská 92',
          click: () => this.$refs.modal6.open()
        },
        {
          latLng: latLng(49.0801717, 18.9278796),
          icon: icon1,
          tooltipTitle: 'Skiservis a požičovňa',
          tooltipSubtitle: 'Martin, Zábavné centrum KOCKA - SEVER',
          click: () => this.$refs.modal5.open()
        },
        {
          latLng: latLng(49.0330547, 19.6745272),
          icon: icon2,
          tooltipTitle: 'Požičovňa bicyklov, elektrobicyklov a kolobežiek',
          tooltipSubtitle: 'Liptovský Ján, Hotel Ďumbier',
          click: () => this.$refs.modal7.open()
        },
        {
          latLng: latLng(49.111817, 19.5456193),
          icon: icon2,
          tooltipTitle: 'Požičovňa, servis a predaj elektrobicyklov a bicyklov',
          tooltipSubtitle: 'Liptovský Trnovec, parkovisko Liptovská Mara oproti Penziónu Ravence',
          click: () => this.$refs.modal11.open()
        },
        {
          latLng: latLng(48.7022062, 21.6619949),          
          icon: icon1,
          tooltipTitle: 'Skiservis a požičovňa',
          tooltipSubtitle: 'Adventure Shop, Obchodná 182, Sečovce',
          click: () => this.$refs.modal12.open()
        },
      ],
      mapOptions: {
        zoomSnap: 0.5
      },
      bounds: latLngBounds([
        [48.6572099, 19.1294844],
        [49.2324567, 19.275118],
        [49.0115507, 21.2348335]
      ])
    }
  },
  methods: {
    scrollTo(ref) {
      const element = this.$refs[ref].$el
      window.scrollTo(0, element.offsetTop - 120)
    },
    openModal(n) {
      this.$refs[`modal${n}`].open()
    },
    showModalOnRoute() {
      const n = parseInt(this.$route.params.n)
      if (n >= 1 && n <= 11) {
        this.openModal(n)
      }
    }
  },
  created() {
    this.$nextTick(() => this.showModalOnRoute())
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.map.mapObject.scrollWheelZoom.disable()
    })
  },
  watch: {
    $route() {
      this.showModalOnRoute()
    }
  }
};
</script>

<template>
  <div class="kontakt-view">
    <article>
      <h1>KONTAKT</h1>

      <div class="boxes">
        <div @click="openModal(10)" class="box" style="background-color:#f71735">
          <Imgix :src="'kontakt/demanova'+($isSummer ? '-leto' : '')+'.jpg'" alt="Demänovská cesta - Tatra Line Resort Demänová" loading="lazy" />
          <h3 style="background:rgba(247, 23, 53, 0.55)">Liptovský Mikuláš</h3>
          <p>
            <a href="https://maps.google.com/maps?f=d&amp;daddr=Tatra+Line+Resort+Demanova" target="_blank" class="button-navigation">Navigovať</a>
            Demänovská cesta - Tatra Line Resort Demänová
          </p>
          <div class="phone"><a href="tel:+421905357133" title="">+421 905 357 133</a></div>
          
          <sweet-modal title="Liptovský Mikuláš - Demänovská cesta" ref="modal10">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Demänovská cesta - Tatra Line Resort Demänová, vedľa čerpacej stanice OMV
              <br><a href="https://maps.google.com/maps?f=d&amp;daddr=Tatra+Line+Resort+Demanova" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>{{ prevedzkaText }}</p>

            <h4 class="no-icon">Odporúčame v okolí:</h4>
            <p>Ubytovanie v <a href="https://vilamaros.sk" target="_blank" title="Apartmánový dom Vila Maroš - Pavčina Lehota">apartmánovom dome Vila Maroš</a></p>

            <template>
              <font-awesome-icon :icon="['far', 'clock']" />
              <h4>Otváracie hodiny:</h4>
              <table v-if="$isSummer">
                <tr><th colspan="2">júl - august</th></tr>
                <tr><td>pondelok - nedeľa</td><td>9:00 - 18:00</td></tr>
              </table>
              <table>
                <tr><th colspan="2">DECEMBER - MAREC</th></tr>
                <tr><td>pondelok - nedeľa</td><td>8:00 - 18:00</td></tr>
              </table>
            </template>
          </sweet-modal>
        </div>

        <div @click="openModal(7)" class="box" style="background-color:#4caf50" v-if="$isSummer">
          <Imgix src="kontakt/dumbier.jpg" alt="Hotel Ďumbier Liptovský Ján" loading="lazy" />
          <h3 style="background:rgba(76, 175, 80, 0.75)">Liptovský Ján</h3>
          <p>
            <a href="https://maps.google.com/maps?f=d&amp;daddr=Hotel+Dumbier,Liptovsky+Jan" target="_blank" class="button-navigation">Navigovať</a>
            Hotel Ďumbier
          </p>
          <div class="phone"><a href="tel:+421905357133" title="">+421 905 357 133</a></div>
          
          <sweet-modal title="Liptovský Ján" ref="modal7">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na mieste:</h4>
            <p>Hotel Ďumbier
              <br><a href="https://maps.google.com/maps?f=d&amp;daddr=Hotel+Dumbier,Liptovsky+Jan" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa bicyklov, elektrobicyklov a kolobežiek</p>

            <font-awesome-icon :icon="['far', 'clock']" />
            <h4>Otváracie hodiny:</h4>
            <table>
              <tr><th colspan="2">júl - august</th></tr>
              <tr><td>pondelok - nedeľa</td><td>9:00 - 18:00</td></tr>
            </table>
          </sweet-modal>
        </div>

        <div @click="openModal(11)" class="box box-new" style="background-color:#4caf50" v-if="$isSummer">
          <Imgix src="kontakt/trnovec.jpg" alt="Parkovisko Liptovská Mara" loading="lazy" />
          <h3 style="background:rgba(76, 175, 80, 0.75)">Liptovský Trnovec</h3>
          <p>
            <a href="https://www.google.com/maps/place/Penzi%C3%B3n+Ravence+-+penzion+%26+restaurant" target="_blank" class="button-navigation">Navigovať</a>
            Parkovisko Liptovská Mara, oproti Penziónu Ravence
          </p>
          <div class="phone"><a href="tel:+421905357133" title="">+421 905 357 133</a></div>
          
          <sweet-modal title="Liptovský Trnovec" ref="modal11">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Liptovský Trnovec, parkovisko Liptovská Mara, oproti Penziónu Ravence
              <br><a href="https://www.google.com/maps/place/Penzi%C3%B3n+Ravence+-+penzion+%26+restaurant" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa elektrobicyklov, bicyklov a kolobežiek</p>

            <font-awesome-icon :icon="['far', 'clock']" />
            <h4>Otváracie hodiny:</h4>
            <table>
              <tr><th colspan="2">júl - august</th></tr>
              <tr><td>pondelok - nedeľa</td><td>9:00 - 18:00</td></tr>
            </table>
          </sweet-modal>
        </div>


        <div @click="openModal(1)" class="box" style="background-color:#01a0e2">
          <Imgix src="kontakt/lmikulas.jpg" alt="Liptovský Mikuláš" loading="lazy" />
          <h3 style="background:rgba(1, 160, 226, 0.65)">Liptovský Mikuláš</h3>
          <p>
            <a href="https://maps.google.com/maps?f=d&amp;daddr=Janosikovo+nabrezie+6,Liptovsky+Mikulas" target="_blank" class="button-navigation">Navigovať</a>
            Jánošíkovo nábrežie 6 - pri Hoteli Jánošík
          </p>
          <div class="phone"><a href="tel:+421905357133 title="">+421 905 357 133</a></div>
          
          <sweet-modal title="Liptovský Mikuláš - CENTRÁLA" ref="modal1">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Jánošíkovo nábrežie 6 - pri Hoteli Jánošík
              <br><a href="https://maps.google.com/maps?f=d&amp;daddr=Janosikovo+nabrezie+6,Liptovsky+Mikulas" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa, skiservis a predaj zimnej výstroje</p>

            <h4 class="no-icon">Odporúčame v okolí:</h4>
            <p>Ubytovanie v <a href="https://vilamaros.sk" target="_blank" title="Apartmánový dom Vila Maroš - Pavčina Lehota">apartmánovom dome Vila Maroš</a></p>

            <font-awesome-icon :icon="['far', 'clock']" />
            <h4>Otváracie hodiny:</h4>
            <table>
                <tr><th colspan="2">OKTÓBER - NOVEMBER</th></tr>
                <tr><td>pondelok</td><td>13:00 - 18:00</td></tr>
                <tr><td>utorok</td><td>13:00 - 18:00</td></tr>
                <tr><td>streda</td><td>13:00 - 18:00</td></tr>
                <tr><td>štvrtok</td><td>13:00 - 18:00</td></tr>
                <tr><td>piatok</td><td>13:00 - 18:00</td></tr>
                <tr><td>sobota</td><td>9:00 - 15:00</td></tr>
                <tr><td>nedeľa</td><td>zatvorené</td></tr>
                <tr><th colspan="2">DECEMBER - MAREC</th></tr>
                <tr><td>pondelok - nedeľa</td><td>8:00 - 18:00</td></tr>
                <tr><th colspan="2">APRÍL - MÁJ</th></tr>
                <tr><td>sobota</td><td>9:00 - 13:00</td></tr>
            </table>            
            <template slot="button" v-if="!$isSummer">
              <a href="https://www.alpinresorts.com/en/ski-rental/partner-ski-ninaj/parameters/3436" target="_blank" title="Rezervovať výstroj na prevádzke" class="button-action">Rezervovať výstroj na prevádzke</a>
            </template>
          </sweet-modal>
        </div>

        <div @click="openModal(2)" class="box" style="background-color:#01a0e2">
          <Imgix src="kontakt/marmot.jpg" alt="Hotel SOREA MARMOT" loading="lazy" />
          <h3 style="background:rgba(1, 160, 226, 0.65)">Demänovská Dolina, Jasná</h3>
          <p>
            <a href="https://maps.google.com/maps?f=d&amp;daddr=Hotel+Sorea+Marmot" target="_blank" class="button-navigation">Navigovať</a>
            Hotel SOREA MARMOT (predtým J. ŠVERMA)
          </p>
          <div class="phone"><a href="tel:+421905357133" title="">+421 905 357 133</a></div>
          
          <sweet-modal title="Demänovská Dolina, Jasná" ref="modal2">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na mieste:</h4>
            <p>Hotel SOREA MARMOT (J. ŠVERMA)
              <br><a href="https://maps.google.com/maps?f=d&amp;daddr=Hotel+Sorea+Marmot" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa a skiservis</p>

            <h4 class="no-icon">Odporúčame v okolí:</h4>
            <p>Ubytovanie v <a href="https://vilamaros.sk" target="_blank" title="Apartmánový dom Vila Maroš - Pavčina Lehota">apartmánovom dome Vila Maroš</a></p>

            <template slot="button" v-if="!$isSummer">
              <a href="https://www.alpinresorts.com/en/ski-rental/partner-ski-ninaj/parameters/3430" target="_blank" title="Rezervovať výstroj na prevádzke" class="button-action">Rezervovať výstroj na prevádzke</a>
            </template>
          </sweet-modal>
        </div>

        <div @click="openModal(3)" class="box" style="background-color:#01a0e2">
          <Imgix src="kontakt/dkubin.jpg" alt="Penzión Kúria Dolný Kubín" loading="lazy" />
          <h3 style="background:rgba(1, 160, 226, 0.65)">Dolný Kubín</h3>
          <p>
            <a href="https://www.google.com/maps/place/Penzi%C3%B3n+K%C3%BAria+**/@49.2126645,19.2732206,17z/data=!3m1!4b1!4m5!3m4!1s0x4715ae18c7d16cc7:0x30aeeb88aab2b76a!8m2!3d49.2126645!4d19.2754093" target="_blank" class="button-navigation">Navigovať</a>
            Penzión Kúria**, Zochova ulica 1013/8
          </p>
          <div class="phone"><a href="tel:+421948454135" title="">+421 948 454 135</a></div>
          
          <sweet-modal title="Dolný Kubín" ref="modal3">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Penzión Kúria**, Zochova ulica 1013/8
              <br><a href="https://www.google.com/maps/place/Penzi%C3%B3n+K%C3%BAria+**/@49.2126645,19.2732206,17z/data=!3m1!4b1!4m5!3m4!1s0x4715ae18c7d16cc7:0x30aeeb88aab2b76a!8m2!3d49.2126645!4d19.2754093" target="_blank">
              Mapa</a></p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa a skiservis</p>

            <font-awesome-icon :icon="['far', 'clock']" />
            <h4>Otváracie hodiny:</h4>
            <table>
              <tr><th colspan="2">do začiatku zimnej sezóny na Kubínskej holi</th></tr>
              <tr><td>piatok</td><td>14:00 - 18:00</td></tr>
              <tr><td>sobota</td><td>8:00 - 12:00</td></tr>
              <tr><td></td><td>13:00 - 18:00</td></tr>
              <tr><th colspan="2">od začiatku zimnej sezóny na Kubínskej holi do 31.3.</th></tr>
              <tr><td>pondelok - nedeľa</td><td>8:00 - 12:00</td></tr>
              <tr><td></td><td>13:00 - 18:00</td></tr>
              <tr><th colspan="2">Vianoce 2024</th></tr>
              <tr><td>24.12.</td><td>8:00 - 12:00</td></tr>
              <tr><td>25.12.</td><td>zatvorené</td></tr>
              <tr><td>31.12.</td><td>8:00 - 12:00</td></tr>
              <tr><td>1.1.</td><td>14:00 - 18:00</td></tr>
            </table>

            <template slot="button" v-if="!$isSummer">
              <a href="https://www.alpinresorts.com/en/ski-rental/partner-ski-ninaj/parameters/3436" target="_blank" title="Rezervovať výstroj na prevádzke" class="button-action">Rezervovať výstroj na prevádzke</a>
            </template>
          </sweet-modal>
        </div>

        <div @click="openModal(30)" class="box" style="background-color:#01a0e2">
          <Imgix src="kontakt/likavka.jpg" alt="Ružomberok - Likavka" loading="lazy" />
          <h3 style="background:rgba(1, 160, 226, 0.65)">Ružomberok - Likavka</h3>
          <p>
            <a href="https://www.google.com/maps/place/Holl%C3%A9ho+151,+034+95+Likavka/@49.094488,19.3035937,17z/data=!3m1!4b1!4m6!3m5!1s0x4715a631a5602ae3:0xcb2752a4a4cc6627!8m2!3d49.0944845!4d19.3061686!16s%2Fg%2F11c4dhg1x2" target="_blank" class="button-navigation">Navigovať</a>
            Hollého 151, Likavka
          </p>
          <div class="phone"><a href="tel:+421905357133" title="">+421 905 357 133</a></div>
          <sweet-modal title="Ružomberok - Likavka" ref="modal30">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Hollého 151, Likavka
              <br><a href="https://www.google.com/maps/place/Holl%C3%A9ho+151,+034+95+Likavka/@49.094488,19.3035937,17z/data=!3m1!4b1!4m6!3m5!1s0x4715a631a5602ae3:0xcb2752a4a4cc6627!8m2!3d49.0944845!4d19.3061686!16s%2Fg%2F11c4dhg1x2" target="_blank">
                Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa a skiservis</p>

            <font-awesome-icon :icon="['far', 'clock']" />
            <h4>Otváracie hodiny:</h4>
            <table>
              <tr><th colspan="2">NOVEMBER</th></tr>
              <tr><td>pondelok - piatok</td><td>13:00 - 18:00</td></tr>
              <tr><td>sobota</td><td>9:00 - 15:00</td></tr>
              <tr><th colspan="2">DECEMBER - MAREC</th></tr>
              <tr><td>pondelok - nedeľa</td><td>8:00 - 18:00</td></tr>
            </table>
          </sweet-modal>
        </div>

        <div @click="openModal(4)" class="box box-new" style="background-color:#01a0e2">
          <Imgix src="kontakt/bb-nove.jpg" alt="Zvolenská cesta 14/4262 Banská Bystrica" loading="lazy" />
          <h3 style="background:rgba(1, 160, 226, 0.65)">Banská Bystrica</h3>
          <p>
            <a href="https://maps.app.goo.gl/K9EAbmzPq8TD8ErXA" target="_blank" class="button-navigation">Navigovať</a>
            Zvolenská cesta 14/4262 (za budovou ZVT, vedľa Okresného súdu)
          </p>
          <div class="phone"><a href="tel:+421905266423" title="">+421 905 266 423</a> / <a href="tel:+421903404818" title="">+421 903 404 818</a></div>
          
          <sweet-modal title="Banská Bystrica" ref="modal4">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Zvolenská cesta 14/4262 (za budovou ZVT, vedľa Okresného súdu)
              <br><a href="https://maps.app.goo.gl/K9EAbmzPq8TD8ErXA" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa, skiservis a predaj zimnej výstroje</p>

            <font-awesome-icon :icon="['far', 'clock']" />
            <h4>Otváracie hodiny:</h4>
            <table>
              <tr><th colspan="2">NOVEMBER</th></tr>
              <tr><td>streda</td><td>12:00 - 18:00</td></tr>
              <tr><td>piatok</td><td>12:00 - 18:00</td></tr>
              <tr><td>sobota</td><td>9:00 - 12:00</td></tr>
              <tr><th colspan="2">DECEMBER - MAREC</th></tr>
              <tr><td>pondelok - piatok</td><td>12:00 - 18:00</td></tr>
              <tr><td>sobota - nedeľa</td><td>8:00 - 12:00</td></tr>
              <tr><th colspan="2">APRÍL - MÁJ</th></tr>
              <tr><td>streda</td><td>16:00 - 18:00</td></tr>
              <tr><td>sobota</td><td>9:00 - 12:00</td></tr>
            </table>

            <template slot="button" v-if="!$isSummer">
              <a href="https://www.alpinresorts.com/en/ski-rental/partner-ski-ninaj/parameters/3442" target="_blank" title="Rezervovať výstroj na prevádzke" class="button-action">Rezervovať výstroj na prevádzke</a>
            </template>
          </sweet-modal>
        </div>

        <div @click="openModal(5)" class="box" style="background-color:#01a0e2">
          <Imgix src="kontakt/martin.jpg" alt="Zábavné centrum KOCKA - SEVER Martin" loading="lazy" />
          <h3 style="background:rgba(1, 160, 226, 0.65)">Martin</h3>
          <p>
            <a href="https://maps.google.com/maps?f=d&amp;daddr=Kocka+MARTIN/@49.0801717,18.9278796" target="_blank" class="button-navigation">Navigovať</a>
            Zábavné centrum KOCKA - SEVER<br>
            Jilemnického 4
          </p>
          <div class="phone"><a href="tel:+421905554649" title="">+421 905 554 649</a> / <a href="tel:+421905357133" title="">+421 905 357 133</a></div>
          <sweet-modal title="Martin" ref="modal5">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Zábavné centrum KOCKA - SEVER<br>Jilemnického 4
              <br><a href="https://maps.google.com/maps?f=d&amp;daddr=Kocka+MARTIN/@49.0801717,18.9278796" target="_blank">
                Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa, skiservis a predaj zimnej výstroje</p>

            <font-awesome-icon :icon="['far', 'clock']" />
            <h4>Otváracie hodiny:</h4>
            <table>
              <tr><th colspan="2">NOVEMBER</th></tr>
              <tr><td>pondelok - piatok</td><td>12:00 - 18:00</td></tr>
              <tr><td>sobota</td><td>11:00 - 18:00</td></tr>
              <tr><td>nedeľa</td><td>zatvorené</td></tr>
              <tr><th colspan="2">DECEMBER - FEBRUÁR</th></tr>
              <tr><td>pondelok - sobota</td><td>11:00 - 18:00</td></tr>
              <tr><td>nedeľa</td><td>zatvorené</td></tr>
              <tr><th colspan="2">MAREC</th></tr>
              <tr><td>pondelok - piatok</td><td>13:00 - 17:00</td></tr>
              <tr><td>sobota</td><td>9:00 - 15:00</td></tr>
              <tr><td>nedeľa</td><td>zatvorené</td></tr>
              <tr><th colspan="2">APRÍL - MÁJ</th></tr>
              <tr><td>piatok</td><td>13:00 - 17:00</td></tr>
              <tr><td>sobota</td><td>9:00 - 15:00</td></tr>
              <tr><td>nedeľa - štvrtok</td><td>zatvorené</td></tr>
            </table>
          </sweet-modal>
        </div>

        <div @click="openModal(6)" class="box" style="background-color:#01a0e2">
          <Imgix src="kontakt/presov.jpg" alt="Prešov" loading="lazy" />
          <h3 style="background:rgba(1, 160, 226, 0.65)">Prešov</h3>
          <p>
            <a href="https://maps.google.com/maps?f=d&amp;daddr=Sabinovska+92,+Prešov,+Slovensko/@49.0115507,21.2348335" target="_blank" class="button-navigation">Navigovať</a>
            Sabinovská 92
          </p>
          <div class="phone"><a href="tel:+421905247767" title="">+421 905 247 767</a></div>
          
          <sweet-modal title="Prešov" ref="modal6">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Sabinovská 92
              <br><a href="https://maps.google.com/maps?f=d&amp;daddr=Sabinovska+92,+Prešov,+Slovensko/@49.0115507,21.2348335" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa, skiservis a predaj zimnej výstroje</p>
          </sweet-modal>
        </div>

        <div @click="openModal(12)" class="box" style="background-color:#01a0e2">
          <Imgix src="kontakt/secovce.jpg" alt="Adventure Shop Sečovce" loading="lazy" />
          <h3 style="background:rgba(1, 160, 226, 0.65)">Adventure Shop Sečovce</h3>
          <p>
            <a href="https://www.google.com/maps/place/Adventure+shop/@48.7022062,21.6619949,3a,75y,312.65h,93.2t/data=!3m7!1e1!3m5!1s3mKfefceJal7yu7OBzYwbA!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fcb_client%3Dmaps_sv.tactile%26w%3D900%26h%3D600%26pitch%3D-3.197702612288765%26panoid%3D3mKfefceJal7yu7OBzYwbA%26yaw%3D312.65127219041796!7i16384!8i8192!4m15!1m8!3m7!1s0x473ed3f06da66a97:0xda2f175ecba4cf4c!2zT2JjaG9kbsOhIDE4Mi8xNiwgMDc4IDAxIFNlxI1vdmNl!3b1!8m2!3d48.7023177!4d21.6619593!16s%2Fg%2F11c2h3q8g_!3m5!1s0x473ed3f06da80377:0x2e793c6f107a518a!8m2!3d48.7023095!4d21.6619589!16s%2Fg%2F11gdm6brpq?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D" target="_blank" class="button-navigation">Navigovať</a>
            Obchodná 182, Sečovce
          </p>
          <div class="phone"><a href="tel:+421917316578" title="">+421 917 316 578</a></div>          
          
          <sweet-modal title="Adventure Shop Sečovce" ref="modal12">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Obchodná 182, Sečovce
              <br><a href="https://www.google.com/maps/place/Adventure+shop/@48.7022062,21.6619949,3a,75y,312.65h,93.2t/data=!3m7!1e1!3m5!1s3mKfefceJal7yu7OBzYwbA!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fcb_client%3Dmaps_sv.tactile%26w%3D900%26h%3D600%26pitch%3D-3.197702612288765%26panoid%3D3mKfefceJal7yu7OBzYwbA%26yaw%3D312.65127219041796!7i16384!8i8192!4m15!1m8!3m7!1s0x473ed3f06da66a97:0xda2f175ecba4cf4c!2zT2JjaG9kbsOhIDE4Mi8xNiwgMDc4IDAxIFNlxI1vdmNl!3b1!8m2!3d48.7023177!4d21.6619593!16s%2Fg%2F11c2h3q8g_!3m5!1s0x473ed3f06da80377:0x2e793c6f107a518a!8m2!3d48.7023095!4d21.6619589!16s%2Fg%2F11gdm6brpq?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa, skiservis a predaj zimnej výstroje
              <br><a href="https://www.adventure-shop.sk" target="_blank">https://www.adventure-shop.sk</a>
            </p>            
          </sweet-modal>
        </div>

        <div @click="openModal(7)" class="box" style="background-color:#4caf50" v-if="!$isSummer">
          <Imgix src="kontakt/dumbier.jpg" alt="Hotel Ďumbier Liptovský Ján" loading="lazy" />
          <h3 style="background:rgba(76, 175, 80, 0.75)">Liptovský Ján</h3>
          <p>
            <a href="https://maps.google.com/maps?f=d&amp;daddr=Hotel+Dumbier,Liptovsky+Jan" target="_blank" class="button-navigation">Navigovať</a>
            Hotel Ďumbier
          </p>
          <div class="phone"><a href="tel:+421905357133" title="">+421 905 357 133</a></div>
          
          <sweet-modal title="Liptovský Ján" ref="modal7">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na mieste:</h4>
            <p>Hotel Ďumbier
              <br><a href="https://maps.google.com/maps?f=d&amp;daddr=Hotel+Dumbier,Liptovsky+Jan" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa bicyklov, elektrobicyklov a kolobežiek</p>

            <font-awesome-icon :icon="['far', 'clock']" />
            <h4>Otváracie hodiny:</h4>
            <table>
              <tr><th colspan="2">júl - august</th></tr>
              <tr><td>pondelok - nedeľa</td><td>9:00 - 18:00</td></tr>
            </table>
          </sweet-modal>
        </div>

        <div @click="openModal(11)" class="box" style="background-color:#4caf50" v-if="!$isSummer">
          <Imgix src="kontakt/trnovec.jpg" alt="Parkovisko Liptovská Mara" loading="lazy" />
          <h3 style="background:rgba(76, 175, 80, 0.75)">Liptovský Trnovec</h3>
          <p>
            <a href="https://www.google.com/maps/place/Penzi%C3%B3n+Ravence+-+penzion+%26+restaurant" target="_blank" class="button-navigation">Navigovať</a>
            Parkovisko Liptovská Mara, oproti Penziónu Ravence
          </p>
          <div class="phone"><a href="tel:+421905357133" title="">+421 905 357 133</a></div>
          
          <sweet-modal title="Liptovský Trnovec" ref="modal11">
            <font-awesome-icon icon="map-marker-alt" />
            <h4>Nájdete nás na adrese:</h4>
            <p>Liptovský Trnovec, parkovisko Liptovská Mara, oproti Penziónu Ravence
              <br><a href="https://www.google.com/maps/place/Penzi%C3%B3n+Ravence+-+penzion+%26+restaurant" target="_blank">Mapa</a>
            </p>

            <h4 class="no-icon">Na predvádzke poskytujeme služby:</h4>
            <p>Požičovňa elektrobicyklov, bicyklov a kolobežiek</p>

            <font-awesome-icon :icon="['far', 'clock']" />
            <h4>Otváracie hodiny:</h4>
            <table>
              <tr><th colspan="2">júl - august</th></tr>
              <tr><td>pondelok - nedeľa</td><td>9:00 - 18:00</td></tr>
            </table>
          </sweet-modal>
        </div>        
      </div>
    </article>
    <l-map
      :zoom="zoom"
      :center="center"
      :bounds="bounds"
      :options="mapOptions"
      ref="map"
      class="map"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker v-for="marker in markers" :key="marker.tooltipSubtitle" :lat-lng="marker.latLng" :icon="marker.icon" @click="marker.click">
        <l-tooltip :options="{ permanent: false, interactive: true }">
          <div>
            <b>{{ marker.tooltipSubtitle }}</b><br>
            {{ marker.tooltipTitle }}
          </div>
        </l-tooltip>
      </l-marker>
    </l-map>
    <app-footer>
      <b>Požičovňa lyžiarskej a snowboardovej výstroje:</b>
        <ul>
          <li><router-link :to="{ name: 'pozicovna' }">Požičovňa lyžiarskej, snowboardovej, skialpovej výstroje</router-link></li>
          <li><router-link :to="{ name: 'skiservis' }">Servis lyží a snowboardov</router-link></li>
          <li><router-link :to="{ name: 'predaj' }">Predaj lyží, snowboardov, lyžiarok a doplnkov</router-link></li>
          <li><router-link :to="{ name: 'deti' }">Detské výstroje</router-link></li>
          <li><router-link :to="{ name: 'celosezonne' }">Celosezónne požičanie</router-link></li>
          <li><router-link :to="{ name: 'pozicovna-elektrobicyklov' }">Elektrobicykle</router-link> a <router-link :to="{ name: 'pozicovna-bicyklov' }">bicykle</router-link></li>
          <li><a href="https://www.kolobezkyliptov.sk/" target="_blank">Kolobežky</a></li>
        </ul>
    </app-footer>
  </div>
</template>

<style lang="scss" scoped>
  $title-color: #0968aa;

  .content-wrapper {
    width: 90%;
    margin: 1rem auto;

    & > p {
      font-size: 18px;
      margin: 1rem 0;
    }
  }

  h1 {
    display: none;
  }

  h2 {
    color: $title-color;
    font-size: 30px;
    margin: 2.5rem 0 2rem;
    text-transform: uppercase;
  }

  .boxes {
    @media (max-width: 720px) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .box {
      cursor: pointer;

      &.box-new:after {
        content: "POZOR! Zmena adresy";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: #f71735;
        color: #fff;
        padding: .1rem 1.5rem .1rem .5rem;
        text-align: center;
        border-radius: 0 0 50px 0;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
        transform: rotateZ(.5deg);
        z-index: -10;
        filter: blur(3px);
        border-radius: 5px;
      }

      ::v-deep h3 {
        font-family: "Montserrat";
        font-size: 23px;
        color: #fff;
        margin: -3.5rem 0 0;
        padding: .5rem 1rem;
        text-transform: uppercase;
        position: relative;
        background: rgba(0,0,0,.5);
      }

      ::v-deep .sweet-content {
        cursor: default;

        [data-icon] {
          float: left;
          font-size: 30px;
          width: 30px;
        }

        p {
          color: #444;
          font-size: 1rem;
          padding: 1rem 0;
          margin-left: 55px;
        }

        h4 {
          font-size: 18px;
          font-weight: bold;
          display: inline-block;
          margin: 0;
          padding: 0;
          margin-left: 25px;
          color: #0968aa;

          &.no-icon {
            margin-left: 55px;
          }
        }

        table {
          margin: 1rem 0 1rem 55px;
          padding: 0;
          font-size: 1rem;

          tr {
            td {
              vertical-align: middle;
              padding: .25rem 1rem .25rem .25rem;

              &:last-child {
                text-align: center;
                padding: .25rem;
              }
            }

            th {
              vertical-align: middle;
              padding: .25rem;
              text-align: left;
              border-top: 1px solid #d9f3ff;
            }
          }
        }
      }

      ::v-deep .sweet-buttons {
        border: none;
        text-align: center;
        padding-bottom: 2rem;
        position: static !important;
      }

      ::v-deep .button-action {
        font-weight: normal;
        font-size: 1.1rem;
        border-radius: 2rem;
        padding: 0.5rem 1.5rem;
      }

      p {
        padding: 1rem;
        margin: 0;
        color: #fff;
      }

      ::v-deep .button-navigation {
        float: right;
        font-size: 12px;
        text-transform: uppercase;
        background: rgba(255,255,255,.9);
        color: #555;
        border-radius: 20px;
        padding: 2px 10px;
      }

      ::v-deep .phone {
        font-family: "Montserrat";
        color: #fff;
        font-weight: 600;
        padding: 0 1rem 2rem;

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  ::v-deep .map {
    height: 450px;

    @media (max-width: 720px) {
      height: 50vh;
    }
  }

  ::v-deep .leaflet-tile-pane {
    .zima & {
      filter: sepia(0.6) grayscale(0.5) hue-rotate(-185deg);
    }
  }
</style>
